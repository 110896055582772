<template>
  <div data-w-id="0731f3bc-a7a4-766a-49d4-67a1cdf88c5c" style="opacity:1;padding: 0 !important;" class="hero">
    <div data-collapse="medium" data-animation="default" data-duration="400" data-doc-height="1" data-easing="ease" data-easing2="ease" role="banner" class="nav w-nav">
      <div class="nav-inner" style="width: 95vw;">
        <div class="nav-logo-wrap">
          <!--a href="#" class="brand w-nav-brand">
            <h1 class="heading-5">Región Conectada</h1>
          </a-->

          <a href="/" class="brand w-nav-brand" style="display: flex; gap: 6px;">
            <img src="@/assets/logo-valcar.svg" loading="lazy" width="72" height="72px" alt="">
            <img src="@/assets/logo-arica.png" loading="lazy" width="72" height="66px" alt="">
            <img src="@/assets/logo-core.png" loading="lazy" width="110ox" height="82px" alt="">
          </a>
        </div>
        <div class="nav-menu-wrap">
          <nav role="navigation" class="nav-menu-2 w-nav-menu">
            <!-- <router-link :style="{ 'color': variant + '!important' }" class="nav-link w-nav-link" to="/encuestas">Encuestas</router-link> -->
            <router-link :to="{ path: '/', hash: 'App' }"  class="nav-link w-nav-link" :style="{ 'color': variant + '!important' }"  >App Móvil</router-link>
            <router-link :to="{ path: '/', hash: 'alertas' }" class="nav-link w-nav-link"  :style="{ 'color': variant + '!important' }">Alerta de Seguridad</router-link>
            <router-link :to="{ path: '/', hash: 'denuncias' }" class="nav-link w-nav-link"  :style="{ 'color': variant + '!important' }">Denuncias</router-link>
            <router-link :to="{ path: '/', hash: 'camaras' }" class="nav-link w-nav-link"  :style="{ 'color': variant + '!important' }">Cámaras</router-link>      
            <router-link :to="{ path: '/', hash: 'red' }" class="nav-link w-nav-link"  :style="{ 'color': variant + '!important' }">Red de Seguridad</router-link>                   
            <router-link :to="{ path: '/', hash: 'Panel-web' }" class="nav-link w-nav-link"  :style="{ 'color': variant + '!important' }">Panel Web</router-link>
            <router-link :to="{ path: '/', hash: 'download' }" class="nav-link w-nav-link"  :style="{ 'color': variant + '!important' }">Contacto</router-link>
<!--             <li v-if="logged" style="color: #FFF;" @click="menuActionClick('logOut')" class="av-link w-nav-link d-flex d-md-none">Salir</li>
            
            <router-link v-if="!logged" :to="{ path: '/login'}" class="nav-link w-nav-link"  :style="{ 'color': variant + '!important' }"><v-btn color="primary">Ingresar</v-btn></router-link>
            <v-menu offset-y v-if="logged"> 
            <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small fab ><v-icon dark>mdi-cog</v-icon></v-btn>
            </template>
            
            <v-list>
        <v-list-item v-for="item in items" :key="item.id" @click="menuActionClick(item.action)"  style="min-height: auto;cursor:pointer; margin-top: 20px;">
        <v-list-item-icon style="margin: 6px 6px; display:flex; gap: 8px;">
          <v-icon dark color="#7E6990" v-text="item.icon"></v-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu> -->

          </nav>
          <div class="menu-button w-nav-button">
            <div class="menu-icon w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from '../../helpers/api/sosdenuncias/sosdenuncias'
export default {
  name: "Header",
  props: ['variant'],
  data: () => ({
    isMobile: false,
    logged: null,
    items: [
      { title: "Configuración", 
        icon: "mdi-cog", 
        action: "goConfig" 
      },
      { title: "Salir", 
        icon: "mdi-logout", 
        action: "logOut" 
      },
    ]
  }),
  mounted() {
    this.logged = localStorage.getItem("userTokenSOSDenuncias") ? true : false
  },
  methods: {
    menuActionClick(action) {
 if (action === "logOut") {
        logout().then(res => {
          this.$router.go()
        })
      } else if (action === "goConfig") {
        this.$router.push('/configuracion')
      }
    },
  } 
  // beforeDestroy() {
  //   if (typeof window !== "undefined") {
  //     window.removeEventListener("resize", this.onResize, { passive: true });
  //   }
  // },
  // mounted() {
  //   this.onResize();
  //   window.addEventListener("resize", this.onResize, { passive: true });
  // },
  // methods: {
  //   onResize() {
  //     this.isMobile = window.innerWidth < 992;
  //   },
  // },
};
</script>
<style scoped>
a {
  text-decoration: none !important;
  color: #fff !important;
}

</style>
