import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"d-flex flex-column justify-center",attrs:{"fluid":"","fill-height":""}},[_c('div',{staticClass:"DeleteContainer"},[_c('p',[_vm._v(" Esto eliminará tu cuenta de nuestro sistema, se revocarán los permisos actuales y no podrás acceder a la aplicación región conectada. Por favor confirma que deseas eliminar tu cuenta escribiendo \"eliminar mi cuenta\" en el siguiente cuadro de texto. ")]),_c(VTextField,{attrs:{"solo":"","placeholder":"eliminar mi cuenta"},model:{value:(_vm.DeleteValid),callback:function ($$v) {_vm.DeleteValid=$$v},expression:"DeleteValid"}}),_c(VBtn,{attrs:{"disabled":_vm.disable,"color":"error"},on:{"click":_vm.deleteAcc}},[_vm._v("eliminar cuenta")])],1),_c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticStyle:{"height":"150px","display":"flex","justify-content":"center","text-align":"center","align-items":"center"}},[_c(VCardText,[_vm._v(" Tu cuenta ha sido eliminada ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }