<template>
  <div class="home">
        <div
      data-w-id="0731f3bc-a7a4-766a-49d4-67a1cdf88c5c"
      style="opacity: 1;z-index:1;"
      class="hero"
    >
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-doc-height="1"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        class="nav w-nav"
      >
        <div class="nav-inner">
          <div class="nav-logo-wrap">
            <a href="#" class="brand w-nav-brand"
              ><img
                src="../../assets/img/Logo.svg"
                loading="lazy"
                width="178"
                alt=""
                class="image-6"
            /></a>
          </div>
          <div class="nav-menu-wrap">
            <nav role="navigation" class="nav-menu-2 w-nav-menu">
              <a style="color: #2250B3 !important;" href="#App" class="nav-link w-nav-link">App Móvil</a>
              <a style="color: #2250B3 !important;" href="#Panel-web" class="nav-link w-nav-link">Panel Web</a>
              <a style="color: #2250B3 !important;" href="#download" class="nav-link w-nav-link">Contacto</a>
            </nav>
            <div class="menu-button w-nav-button">
              <div class="menu-icon w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      data-w-id="4e969de5-dbb7-4e4a-e205-2edd565b379b"
      style="opacity: 1; min-height: 100vh; height: auto"
      class="section center"
    >
      <img src="../../assets/img/arrow.svg" alt="" class="arrow" />
      <div
        data-w-id="6b220cb1-4b9c-7fb0-75b9-53ff4a6c35a2"
        style="
          margin-top: 120px;
          padding-bottom: 20px;
          -webkit-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          opacity: 1;
        "
        class="hero-content"
      >
        <h1
          data-w-id="f8a3a51b-0bd7-225c-c6a0-97acfcbbd7ee"
          style="opacity: 1; text-align:left;"
          class="heading-3"
        >
          Comuna Conectada
        </h1>
        <h3
          data-w-id="f4820783-e165-dba0-a4bc-61fedcc80587"
          style="opacity: 1; text-align: justify !important; width: 800px"
          class="hero-subhead _2"
        >
          Lucha contra la delincuencia en tu vecindario con
          <span style="font-weight: 800">“Comuna Conectada”</span>. Nuestra
          plataforma te ofrece la oportunidad de denunciar delitos y comunicarte
          con nuestro sistema de atención “Click2Call” o Call Center de
          videoconferencia. Además, nuestra Aplicación Móvil cuenta con un
          Alerta de Seguridad personal a la cual le puedes agregar Cámaras IP
          para una mayor y mejor protección. Todas las denuncias se envían al
          Panel WEB de comunicación total para ser localizadas en el plano.
          <br />
          <span style="text-transform: uppercase"
            >¡Únete a nosotros y conviértete en un protector de tu comuna!</span
          >
        </h3>
        <a
          href="https://play.google.com/store/apps/details?id=com.naturalphone.comunaconectada"
          data-w-id="d8c83ab6-c99c-87ad-481f-2c11e25620f1"
          style="opacity: 1"
          class="button w-button"
          >Descarga App</a
        >
      </div>
      <div
        data-delay="4000"
        data-animation="slide"
        class="slider w-slider"
        data-autoplay="true"
        data-easing="ease"
        data-hide-arrows="false"
        data-disable-swipe="true"
        data-autoplay-limit="0"
        data-nav-spacing="3"
        data-duration="500"
        data-infinite="true"
      >
        <div class="w-slider-mask">
          <div class="slide w-slide"></div>
          <div class="slide-2 w-slide"></div>
          <div class="slide-3 w-slide"></div>
          <div class="slide-4 w-slide"></div>
        </div>
        <!--       <div class="w-slider-arrow-left">
        <div class="w-icon-slider-left"></div>
      </div>
      <div class="right-arrow-2 w-slider-arrow-right">
        <div class="w-icon-slider-right"></div>
      </div> -->
        <!--    <div class="w-slider-nav w-slider-nav-invert w-round"></div> -->
      </div>
      <div class="div-block-16"></div>
    </div>
    <div id="App" class="section">
      <div id="features" class="feature-wrap">
        <div
          data-w-id="4593c4b7-56e8-060a-be57-648b0e08fc65"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <div
            data-w-id="4593c4b7-56e8-060a-be57-648b0e08fc66"
            style="opacity: 1; font-size: 24px; text-align:left;color: #FF6200; text-transform:uppercase;"
          >
            COMUNA Conectada
          </div>
          <h2
            data-w-id="b5c18a17-89b5-0e4d-1546-72a605699114"
            style="opacity: 1; color: #FF6200; text-align:left;"
          >
            APP Móvil
          </h2>
          <p
            data-w-id="4593c4b7-56e8-060a-be57-648b0e08fc6c"
            style="opacity: 1; font-size: 20px"
            class="paragraph-2"
          >
            Comuna Conectada es una Aplicación Móvil que permite a los usuarios
            realizar denuncias de delitos e incivilidades y comunicarse con el
            sistema “Click2Call” a través de video conferencias o video mails.
            El Aplicativo Móvil "Comuna Conectada" permite que todo usuario
            acceda a una Alerta de Seguridad personal ante cualquier emergencia
            que el usuario tenga, la cual al activarse notificará tanto al Panel
            WEB de Atención Ciudadana como a la Red de Seguridad personal que
            todo vecino podrá generar.
          </p>
          <h3
            data-w-id="3be32b1e-56af-bf8b-505b-406cb36197d2"
            style="opacity: 1; text-align: left;"
          >
            Comuna Conectada cuenta con siete elementos básicos de comunicación,
            los cuales son:
          </h3>
        </div>
        <div class="feature-image">
          <img
            src="../../assets/img/Phone-1.png"
            width="666"
            style="opacity: 1; margin-top: 30px;"
            data-w-id="ca7ee6f6-0cc4-1040-5449-30cf20e4419e"
            srcset="
              ../../assets/img/Phone-1-p-500.png   500w,
              ../../assets/img/Phone-1-p-800.png   800w,
              ../../assets/img/Phone-1-p-1080.png 1080w,
              ../../assets/img/Phone-1-p-1600.png 1600w,
              ../../assets/img/Phone-1-p-2000.png 2000w,
              ../../assets/img/Phone-1.png        2110w
            "
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 450px, 32vw"
            alt=""
            class="image-2 none"
          />
        </div>
      </div>

      <div
        class="feature-wrap icons numbers"
        style="padding-left: 20px; padding-right: 20px; gap: 20px;"
      >
        <div class="feature-content icons numbers">
          <p
            style="color: #0a3e84; font-weight: 800; font-size: 5rem"
            class="feature-icon number"
          >
            1
          </p>
          <p
            data-w-id="9b3d29e2-b793-f300-069f-aad198e15c8a"
            style="opacity: 1; text-align: left"
            class="paragraph-3"
          >
            Botón de Alerta de Seguridad personal, notificará tanto al Panel WEB
            de Atención ciudadana como a la Red de Seguridad personal ante una
            emergencia.
          </p>
        </div>
        <div class="feature-content icons numbers">
          <p
            style="color: #0a3e84; font-weight: 800; font-size: 5rem"
            class="feature-icon number"
          >
            2
          </p>
          <p
            data-w-id="7545d0e6-4b58-3ff6-31b2-8f5a9e00498b"
            style="opacity: 1; text-align: left"
            class="paragraph-4"
          >
            Sistema de Atención o Call left de Video Conferencia de nombre
            “Click2Call”.
          </p>
        </div>
        <div class="feature-content icons numbers">
          <p
            style="color: #0a3e84; font-weight: 800; font-size: 5rem"
            class="feature-icon number"
          >
            3
          </p>
          <p
            data-w-id="2cd02545-1c5c-726e-48c7-63bed45971c8"
            style="opacity: 1; text-align: left"
            class="paragraph-5"
          >
            Sistema de Denuncias e Invicilidades propiamente tal.
          </p>
        </div>
        <div class="feature-content icons numbers">
          <p
            style="color: #0a3e84; font-weight: 800; font-size: 5rem"
            class="feature-icon number"
          >
            4
          </p>
          <p
            data-w-id="2a2b3181-a3c5-152c-2582-9c4aa29bf970"
            style="opacity: 1; text-align: left"
            class="paragraph-6"
          >
            Panel WEB de comunicación total o Administración que recepciona las
            denuncias realizadas.
          </p>
        </div>
        <div class="feature-content icons numbers">
          <p
            style="color: #0a3e84; font-weight: 800; font-size: 5rem"
            class="feature-icon number"
          >
            5
          </p>
          <p
            data-w-id="2a2b3181-a3c5-152c-2582-9c4aa29bf970"
            style="opacity: 1; text-align: left"
            class="paragraph-6"
          >
            Cámara IP de visualización del interior del hogar, comercio etc, a
            través de la Aplicación Móvil "Comuna Conectada".
          </p>
        </div>
        <div class="feature-content icons numbers">
          <p
            style="color: #0a3e84; font-weight: 800; font-size: 5rem"
            class="feature-icon number "
          >
            6
          </p>
          <p
            data-w-id="2a2b3181-a3c5-152c-2582-9c4aa29bf970"
            style="opacity: 1; text-align: left"
            class="paragraph-6 number6"
          >
            Red de Seguridad Personal.
          </p>
        </div>
        <div class="feature-content icons numbers">
          <p
            style="color: #0a3e84; font-weight: 800; font-size: 5rem"
            class="feature-icon number"
          >
            7
          </p>
          <p
            data-w-id="2a2b3181-a3c5-152c-2582-9c4aa29bf970"
            style="opacity: 1; text-align: left"
            class="paragraph-6"
          >
            Sistema de Noticias de la Comuna como por ejemplo el Twitter de la
            Comuna.
          </p>
        </div>
      </div>

      <div class="feature-wrap reverse">
        <div
          data-w-id="cafe0045-d304-79d9-8f68-af3adaed06e9"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2 class="black-text">
            <strong
              data-w-id="4239225a-2078-e0e4-bec5-095de772ed0f"
              style="opacity: 1; color: #FF6200; text-align:left;"
              >Sistema de Call Center de Video Conferencia “Click2Call”</strong
            >
          </h2>
          <p
            data-w-id="45835889-a334-efa8-f1f2-05d3ba28c07a"
            style="opacity: 1; text-align: justify"
            class="paragraph-7"
          >
            A través del Sistema o Call Center de Atención por Video Conferencia
            o Video Llamada de nombre <strong>“Click2Call”</strong> los
            ciudadanos que se encuentren utilizando la Aplicación
            <strong>“Comuna Conectada”</strong> podrán establecer una
            comunicación por video llamada con el Call Center de Video
            Conferencia.<br /><br />Los usuarios de la Aplicación Móvil podrán
            contactar a los operadores a través del Módulo de Atención, en donde
            tendrán dos alternativas de contacto que a continuación se
            describen:<br /><br />‍<strong>Sistema de Video Llamada:</strong>
            Esta opción permite al ciudadano comunicarse cara a cara con el
            operador, utilizando su dispositivo móvil. <br /><br />‍<strong
              >Sistema de Video Mail:</strong
            >
            Esta opción permite que el ciudadano grabe un video y lo envié por
            email al operador. Este email podrá ser contestado cuando el
            operador así lo determine. <br />‍
          </p>
        </div>
        <div
          data-w-id="cafe0045-d304-79d9-8f68-af3adaed06f2"
          style="opacity: 1; text-align: justify"
          class="feature-image"
        >
          <img
            src="../../assets/img/Phone-2.png"
            width="386"
            style="opacity: 1"
            data-w-id="cafe0045-d304-79d9-8f68-af3adaed06f3"
            srcset="../../assets/img/Phone-2-p-500.png 500w, ../../assets/img/Phone-2.png 620w"
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 386px, 23vw"
            alt=""
            class="image-2"
          />
        </div>
      </div>
      <div class="feature-wrap">
        <div
          data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b7e"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b81"
            style="opacity: 1; text-align: justify"
            class="black-text"
          >
            <strong class="bold-text-4"
              >Sistema de Denuncias de la Plataforma</strong
            >
          </h2>
          <p
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
            style="opacity: 1; text-align: justify"
            class="paragraph-8"
          >
            <strong
              >Sistema de Denuncias de la Plataforma “Comuna Conectada”. </strong
            >Esta alternativa permite a cada ciudadano, generar denuncias de
            Seguridad Pública ya sea de delitos o incivilidades grabadas las
            cuales serán enviadas en forma inmediata al Panel WEB del Sistema y
            que se encontrara en servidores propios del sistema.<br />‍<br />
          </p>

          <h2
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b81"
            style="opacity: 1; text-align: justify"
            class="black-text"
          >
            <strong class="bold-text-4"
              >Contamos con un Panel WEB de Comunicación Total.</strong
            >
          </h2>
          <p class="paragraph-8">
            <strong>Pantalla de Denuncias: </strong>En esta pantalla se
            seleccionará el tipo de denuncia que se desea generar.
          </p>
        </div>
        <div
          data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b85"
          style="opacity: 1; text-align: justify"
          class="feature-image"
        >
          <img
            src="../../assets/img/Get-started-–-23.png"
            width="341"
            style="opacity: 1"
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b86"
            alt=""
            class="image-2"
          />
        </div>
      </div>
      <div id="how-to-use" class="feature-wrap">
        <div class="feature-image _2">
          <img
            src="../../assets/img/Phone-4.png"
            width="519"
            style="opacity: 1"
            data-w-id="0585a81c-ee42-8b56-bc8e-bd5829463221"
            srcset="../../assets/img/Phone-4-p-500.png 500w, ../../assets/img/Phone-4.png 602w"
            sizes="(max-width: 479px) 240px, (max-width: 767px) 350px, 20vw"
            alt=""
            class="image-2"
          />
        </div>
        <div
          data-w-id="0585a81c-ee42-8b56-bc8e-bd582946320a"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2 class="black-text">
            <strong
              data-w-id="32564d77-ebe3-ad31-61ee-f1e91e7e3260"
              style="opacity: 1"
              class="bold-text-2"
              >Pantalla de Generación de una Denuncia</strong
            >
          </h2>
          <p
            data-w-id="6be42438-4e11-0d64-60b4-7af370562410"
            style="opacity: 1"
            class="paragraph-9"
          >
            En esta pantalla del smartphone se comenzará a grabar la denuncia,
            utilizando la cámara externa del propio smartphone del ciudadano.
            Por ejemplo para este caso puede ser un automóvil robado.
          </p>
        </div>
      </div>
      <div class="feature-wrap icons _3" style="padding-bottom: 0;">
        <div class="feature-content icons denuncia">
          <img
            src="../../assets/img/Denuncias-2.png"
            width="80"
            style="opacity: 1;width: 150px;
            height: 350px;"
            data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae80"
            alt=""
            class="feature-icon"
          />
          <p
            data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae81"
            style="opacity: 1"
            class="paragraph-3"
          >
            <strong>Denuncias Generadas por el Ciudadano: </strong>En esta
            pantalla se podrá encontrar un detalle de todas las denuncias que ha
            generado el ciudadano.
          </p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons denuncia">
          <img
            src="../../assets/img/Denuncias-1.png"
            width="80"
            style="opacity: 1;width: 150px;
            height: 350px;"
            data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae85"
            alt=""
            class="feature-icon"
          />
          <p
            data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae86"
            style="opacity: 1"
            class="paragraph-4"
          >
            <strong>Detalle de la Denuncia: </strong>En esta pantalla se podrá
            encontrar el detalle de la denuncia, con la fecha, hora y ubicación
            en el plano.
          </p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons denuncia">
          <img
            src="../../assets/img/Denuncias-3.png"
            width="74"
            style="opacity: 1;width: 150px;
            height: 350px;"
            data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae8a"
            alt=""
            class="feature-icon"
          />
          <p
            data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae8b"
            style="opacity: 1"
            class="paragraph-5"
          >
            <strong>Noticias Regionales tipo Twitter: </strong>Esta alternativa
            permite a cada ciudadano leer online lo que la Región va publicando.
          </p>
        </div>
      </div>
      <div class="feature-wrap">
        <div
          data-w-id="2f7191c3-1837-a098-825b-cadd95d8521d"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2
            data-w-id="2f7191c3-1837-a098-825b-cadd95d8521e"
            style="opacity: 1"
            class="black-text"
          >
            <strong class="bold-text-4">Red de Seguridad Personal</strong>
          </h2>
          <p
            data-w-id="2f7191c3-1837-a098-825b-cadd95d85221"
            style="opacity: 1"
            class="paragraph-8"
          >
            <strong
              >Sistema de Denuncias de la Plataforma “Comuna Conectada”.</strong
            >
            En forma adicional y complementaria a la Comunicación con la Central
            de Atención, como este Sistema contempla la generación de Redes de
            Seguridad para el Usuario de al menos 5 familiares, vecinos, etc,
            estos también tendrán la posibilidad de descargar la Aplicación
            Móvil Comuna Conectada” a sus smartphone y así ante cualquier
            emergencia que tenga el usuario principal, podrán comunicarse con
            éste todas o parte de las personas que pertenecen a su Red en forma
            simultánea por video conferencia, en una modalidad de Multipunto de
            Comunicación. Esta Red de Seguridad Personal, actúa incluso como el
            primer elemento de ayuda y auxilio ante cualquier Emergencia que
            tenga el Usuario, considerando que al generar éste una Alarma
            personal vía el Aplicativo, los primeros en comunicarse en igualdad
            de condiciones con el personal del Centro de Atención o Call Center,
            serán las personas pertenecientes a su Red Personal de Seguridad.
          </p>
        </div>
        <div
          data-w-id="2f7191c3-1837-a098-825b-cadd95d8522b"
          style="opacity: 1"
          class="feature-image"
        >
          <img
            src="../../assets/img/Red-seguridad.png"
            width="677"
            style="opacity: 1"
            data-w-id="2f7191c3-1837-a098-825b-cadd95d8522c"
            srcset="
              ../../assets/img/Red-seguridad-p-500.png   500w,
              ../../assets/img/Red-seguridad-p-800.png   800w,
              ../../assets/img/Red-seguridad-p-1080.png 1080w,
              ../../assets/img/Red-seguridad.png        1260w
            "
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 450px, 32vw"
            alt=""
            class="image-2 none"
          />
        </div>
      </div>
      <div id="how-to-use" class="feature-wrap">
        <div class="feature-image _2">
          <img
            src="../../assets/img/SOScam4x.png"
            width="519"
            style="opacity: 1"
            data-w-id="0fd0781f-86a0-df50-7b25-c081a0bd54fb"
            srcset="../../assets/img/SOScam4x.png 500w, ../../assets/img/SOScam4x.png 870w"
            sizes="(max-width: 479px) 240px, (max-width: 767px) 350px, 20vw"
            alt=""
            class="image-2 none"
          />
        </div>
        <div
          data-w-id="0fd0781f-86a0-df50-7b25-c081a0bd54fc"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2 class="black-text">
            <strong
              data-w-id="0fd0781f-86a0-df50-7b25-c081a0bd54fe"
              style="opacity: 1"
              class="bold-text-2"
              >Cámara WIFI de visualización través de la Aplicación “Comuna
              Conectada”</strong
            >
          </h2>
          <p
            data-w-id="0fd0781f-86a0-df50-7b25-c081a0bd5500"
            style="opacity: 1"
            class="paragraph-9"
          >
            La Cámara WIFI la cual puede ser conectada a la Aplicación Móvil
            “Comuna Conectada”, es complementaria a la Aplicación Móvil “Comuna
            Conectada”, todo lo cual posibilitará que cada usuario que la tenga
            instalada pueda visualizar la imagen en tiempo real de su hogar,
            comercio, etc, en todo momento a través de la Aplicación Móvil
            “Región Conectada”, incluso con la posibilidad de generar audios
            disuasivos o de comunicación dependiendo del caso.Estas Cámaras
            también podrán generar Alertas ante la detección de movimientos, por
            ejemplo cuando el hogar se encuentre sin moradores, produciendo en
            forma inmediata una comunicación con el usuario, el cual podrá
            activar la Alarma, la cual será escuchada tanto por el Centro de
            Asistencia o Call Center, como así también, por la Red de Seguridad
            Personal del propio usuario principal a través del Aplicativo Móvil
            “Comuna Conectada”, produciéndose en forma inmediata la comunicación
            respectiva.Independiente de lo anterior y con el objeto de tener
            pruebas grabadas ante actos delictuales para ser presentadas en los
            Tribunales de Justicia, estas Cámaras también tienen la posibilidad
            de ir grabando lo que sucede en el hogar, local, etc, en todo
            momento, considerando que pueden contar con una Tarjeta de Grabación
            TF que se le inserta a la propia Cámara y que posibilita la acción
            anteriormente citada.
          </p>
        </div>
      </div>
    </div>
    <div id="Panel-web" class="section wide img">
      <div
        data-w-id="b126983d-9126-fc06-c81d-1318685e2add"
        style="
          -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          opacity: 1;
        "
        class="wrap"
      >
        <div class="title">
          <strong class="bold-text">Panel WEB de Comuna Conectada</strong>
        </div>
        <h2 class="heading-6">
          En el Panel WEB de <strong>“Comuna Conectada”</strong> se registrarán
          las denuncias que han ido ingresando a través de la Aplicación Móvil,
          este panel cuenta con mapas de calor, detalle de las denuncias
          realizadas, atención de las denuncias en tiempo real por un operador
          del sistema.
        </h2>
      </div>
      <div class="wrapper">
        <img
          src="../../assets/img/Lapto-2.png"
          style="opacity: 1"
          data-w-id="c386af35-0586-d8e3-a5c8-af4cd2c6312e"
          sizes="(max-width: 479px) 320px, (max-width: 767px) 550px, (max-width: 991px) 650px, 1200px"
          srcset="
            ../../assets/img/Lapto-2-p-500.png   500w,
            ../../assets/img/Lapto-2-p-800.png   800w,
            ../../assets/img/Lapto-2-p-1080.png 1080w,
            ../../assets/img/Lapto-2-p-1600.png 1600w,
            ../../assets/img/Lapto-2.png        1704w
          "
          alt=""
          class="image-5"
        />
      </div>
    </div>
    <div id="download" class="section wide purple">
      <div
        data-w-id="376e2a1f-ec70-0dab-11ea-b1863101e37b"
        style="
          -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          opacity: 1;
        "
        class="wrap"
      >
        <h2>¿Estas listo para comenzar ?</h2>
        <div class="button-wrap">
          <a href="https://play.google.com/store/apps/details?id=com.naturalphone.comunaconectada" class="button w-button">Descargar App</a>
          <a href="#" class="button contact w-button" style="color: white;">Contactar</a>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="wrap">
        <div class="logos">
        <img src="../../assets/img/Logo.svg" width="238" alt="" />
        <img src="../../assets/img/naturalphone.png" width="230" alt="" />
        </div>
        <div class="footer-list">
          <router-link class="footer-link" to="/">Preguntas Frecuentes</router-link>
          <router-link class="footer-link" to="/terminos">Términos y Condiciones</router-link>
          <router-link class="footer-link" to="/politica-de-privacidad">Políticas de Privacidad</router-link>
          <router-link class="footer-link" to="/">Presentación APP</router-link>
        </div>
      </div>
    </div>
    <Drawer />
  </div>
</template>

<script>
/* import Drawer from "../components/drawer/Drawer.vue"; */

export default {
  name: 'Home',
/*   components: {
    Drawer
  }, */
}
</script>
<style>
@import '../../assets/css/webflow.css';
@import '../../assets/css/normalize.css';
@import '../../assets/css/region-conectada-9a28433434d626876a891d.webflow.css';

@media (min-width: 768px) {
  .number6 {
    margin-bottom: 45px !important;
  }
}
.footer-link {
  color: #FFF !important;
  text-decoration: none;
}
.logos {
  display: flex;
  gap: 50px;
  justify-content: center;
}

@media (max-width: 568px) {
  .logos {
    gap: 20px;
    flex-direction: column;
  }
}
</style>