<template>
    <v-container fluid style="" fill-height class="d-flex flex-column justify-center">
		<img v-if="!success" :src="require('@/assets/img/Logo.svg')" style="max-width: 100px; width: 100%;"/>
        <div v-if="loading" class="loader"></div>
        
        <div v-if="!success">

            <!-- error  get recovery data-->
        <div v-if="error">
            <p class="mt-2 text-center">Error token inválido</p>
            <v-btn
			    class="mt-2"
               align="center"
				color="#5E85EF"
				dark
			    @click="$router.push({name: 'Home'})"
			    >
				Volver al Inicio
				</v-btn>
        </div>

        <!-- data device -->
        <div v-else v-show="!loading" style=" margin-top: 20px; display:flex;flex-direction:column; gap:20px; justify-content:center;">
            <v-card elevation="6">
              <v-card-title>
                <h4 style="color:#000;">Nuevo Dispositivo Detectado</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-listdense>
                <v-list-item>
                  <v-list-item-content>Marca:</v-list-item-content>
                  <v-list-item-content class="align-end" style="font-weight: 600;">
                   {{ device.brand }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Modelo:</v-list-item-content>
                  <v-list-item-content class="align-end" style="font-weight: 600;">
                    {{ device.model }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Nombre:</v-list-item-content>
                  <v-list-item-content class="align-end" style="font-weight: 600;">
                    {{ device.name }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Sistema:</v-list-item-content>
                  <v-list-item-content class="align-end text-capitalize" style="font-weight: 600;">
                    {{ device.system }}
                  </v-list-item-content>
                </v-list-item>
              </v-listdense>
            </v-card>

            <v-btn
                align="center"
				color="#5E85EF"
				dark
				@click="accountRec"
                :loading="loadingbtn"
			>
				Recuperar cuenta
			</v-btn>
                <v-btn
               align="center"
				color="#5E85EF"
				dark
			    @click="$router.push({name: 'Home'})"
			    >
				Volver al Inicio
				</v-btn>
        </div>
        </div>


        <!-- success --> 
        <div v-else>
            <v-card elevation="2" style="padding:20px;">
		        <img :src="require('@/assets/img/Logo.svg')" style="max-width: 100px; width: 100%; display:flex; margin:0 auto;"/>
                <v-card-title><v-icon class="mr-2" color="success" large>mdi-check-circle</v-icon>Correo de reactivación de cuenta enviado</v-card-title>
                <v-btn  class="" style="display:flex; margin: 0 auto;" align="center" color="#5E85EF" dark @click="$router.push({name: 'index'})">
				Volver al Inicio
				</v-btn>
            </v-card>
        </div>

    </v-container>
</template>

<script>
import { getAccountRecoveryData, accountRecovery } from '../../helpers/api/sosdenuncias/sosdenuncias'
export default {
    data () {
        return {
        error: false,
        success: false,
        loading: true,
        loadingbtn: false,
        token: '',
        device: {}
        }
    },
    methods: {
        getAccountRecovery() {
            this.token = this.$route.query.t
            const data = {t: this.$route.query.t} 
            getAccountRecoveryData(data).then(res => {
                console.log(res.status);
                if (res.status == 404) {
                this.loading = false;
                this.error = true
                } else {
                this.loading = false;
                this.error = false
                this.device = res.data.device
        }
            }).catch(e => {
                console.log(e);
            })
        },
        accountRec() {
            this.loadingbtn = true
            const data = {t: this.token}
            accountRecovery(data).then(res => {
                this.loadingbtn = false
                this.error = false
                this.success = true
            }).catch(e => {
                console.log(e);
                this.loadingbtn = false
            })
        }
    },
    mounted() {
        this.getAccountRecovery()
            const token = localStorage.getItem("userTokenSOSDenuncias");
            !token && this.$router.push({path: "/login",query: { redirect_to: `${this.$route.path}?t=${this.$route.query.t}`   },params:{t:this.$route.query.t}});
    }
}
</script>

<style lang="scss" scoped>
.loader {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 30px;
  animation: l1 .6s linear infinite alternate;
}
.loader:before {
  content:"Cargando Datos...";
  font-size: 16px;
}
@keyframes l1 {to{opacity: 0}}
</style>