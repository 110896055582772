import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/index.vue'
import PreguntasFrecuentes from '../components/PreguntasFrecuentes/index.vue'
import Checkout from '@/components/checkout/index.vue'
import NetworkInvite from '@/components/Pages/network_invite'
import Login from '@/components/Pages/login'
import Register from '@/components/Pages/register'
import ResetPass from '@/components/Pages/reset-pass'
import ResetPassRequest from '@/components/Pages/resetpassrequest'
import account_recovery from '@/components/Pages/account_recovery'
import DeleteAccount from '@/components/Pages/delete-account'
import politicas from '@/components/Pages/politicas'
import PoliticasConHeader from '@/components/Pages/politicasConHeader'
import Terminos from '@/components/Pages/terminos'
import terminos from '@/components/Pages/terminos'
import TerminosConHeader from '@/components/Pages/terminosConHeader'
import Promo from '@/components/Pages/Promo'
import Promocion from '@/components/Pages/Promocion'
import Config from '@/components/Pages/Config/Config'
import login2FA from '@/components/Pages/2FA/login2FA'
import StoreRedirect from '@/components/Pages/StoreRedirect'
import Wheel from '@/components/Pages/Wheel'
import ConcourseResults from '@/components/Pages/ConcourseResults'
/* import Encuestas from '@/components/Pages/encuestas' */

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/network_invite',
    name: 'NetworkInvite',
    component: NetworkInvite
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login2FA',
    name: 'login2FA',
    component: login2FA
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/registrate',
    name: 'Promo',
    component: Promo
  },
/*   {
    path: '/promocion',
    name: 'Promocion',
    component: Promocion
  }, */
  {
    path: '/reset-pass',
    name: 'ResetPass',
    component: ResetPass
  },
  {
    path: '/resetpassrequest',
    name: 'ResetPassRequest',
    component: ResetPassRequest
  },
  {
    path: '/delete-account',
    name: 'DeleteAccount',
    component: DeleteAccount
  },
  {
    path: '/account_recovery',
    name: 'account_recovery',
    component: account_recovery
  },
  {
    path: '/configuracion',
    name: 'Config',
    component: Config
  },

  {
    path: '/politicas-app',
    name: 'politicas',
    component: politicas
  },
  {
    path: '/politica-de-privacidad',
    name: 'politicas',
    component: politicas
  },
  {
    path: '/terminos-app',
    name: 'terminos',
    component: terminos
  },
  {
    path: '/terminos',
    name: 'terminos',
    component: terminos
  },
  {
    path: '/store-redirect',
    name: 'StoreRedirect',
    component: StoreRedirect
  },
  {
    path: '/wheel',
    name: 'Wheel',
    component: Wheel
  },
  {
    path: '/ganadores-camaras',
    name: 'ConcourseResults',
    component: ConcourseResults
  },
  // {
  //   path: '/preguntas_frecuentes',
  //   name: 'PreguntasFrecuentes',
  //   component: PreguntasFrecuentes
  // },
  // {
  //   path: '/Checkout',
  //   name: 'Checkout',
  //   component: Checkout
  // }

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
