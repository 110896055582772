<template>
<main>
      <div
      data-w-id="0731f3bc-a7a4-766a-49d4-67a1cdf88c5c"
      style="opacity: 1"
      class="hero"
    >
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-doc-height="1"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        class="nav w-nav"
      >
        <div class="nav-inner">
          <div class="nav-logo-wrap">
            <a href="/" class="brand w-nav-brand"
              ><img
                src="../../assets/img/Logo.svg"
                loading="lazy"
                width="178"
                alt=""
                class="image-6"
            /></a>
          </div>
          <div class="nav-menu-wrap">
            <nav role="navigation" class="nav-menu-2 w-nav-menu">
              <a href="/#App" class="nav-link w-nav-link">App Móvil</a>
              <a href="/#Panel-web" class="nav-link w-nav-link">Panel Web</a>
              <a href="/#download" class="nav-link w-nav-link">Contacto</a>
            </nav>
            <div class="menu-button w-nav-button">
              <div class="menu-icon w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="terminos-container">
    <h1 class="title">
      Política de Privacidad de la Aplicación para Móviles “COMUNA CONECTADA”.
    </h1>
    <p>
      Le solicitamos que lea detenidamente esta Política de Privacidad para
      comprender como será tratada su información personal mientras Plataformas
      digitales.
    </p>
    <p>
      “COMUNA CONECTADA” respeta la política de privacidad de cada uno que
      visiten el Portal WEB suscriptores que formen parte de la Privacidad
      refleja la información que esa información, además de cómo contactarnos si
      tiene una duda o pregunta. Deseamos facilitarle el máximo control a su al
      Cuando lo desee Usted será capaz de
    </p>
    <ul>
      <li>Acceder a su información personal.</li>
      <li>Retirar su información personal de nuestra base de datos.</li>
      <li>
        Corregir cualquier dato que Usted haya suministrado de forma equivocada.
      </li>
    </ul>

    <p>
      Esta Aplicación Móvil de nombre “COMUNA CONECTADA” la opera y se encuentra
      desarrollada por NATURALPHONE S.A., la cual es una Aplicación que le
      ayudara de forma preventiva en su seguridad personal, a fin de obtener una
      mejor calidad de vida, siendo una herramienta de carácter preventiva y de
      autocuidado basado en el monitoreo de su propio smartphone y acceso a una
      Red de Seguridad Personal que el propio usuario deberá construir. Una vez
      instalada y utilizada la Aplicación Móvil “COMUNA CONECTADA”, podrán
      llegarle diferentes notificaciones algunas de las cuales le solicitarán
      recabar información sobre Usted y otras en donde se le enviará información
      sobre diferentes temas en el ámbito de la seguridad, o bien, de beneficios
      que su persona podrá obtener con el uso y mantención de ésta Aplicación en
      su Móvil personal, así como Alertas ante eventuales delitos. Todo lo
      indicado Usted declara aceptar y consentir.
    </p>
    <p>
      Al momento que Usted utiliza nuestro Portal WEB y nuestra Aplicación Móvil
      “COMUNA CONECTADA” nosotros no accedemos a ninguna información personal, a
      menos que Usted así lo decida y que la empresa se lo solicite.
    </p>
    <p>
      Si accede a esta Aplicación Móvil o la utiliza a través de un sitio WEB
      operado por un tercero, este también podrá recabar información sobre
      Usted, lo cual Usted acepta y autoriza. Si Usted baja la Aplicación Móvil
      “COMUNA CONECTADA” a su smartphone, significa que Usted acepta tanto los
      Términos y Condiciones de uso, como así también, la Política de Privacidad
      de “COMUNA CONECTADA”, lo cual, asimismo, declara por el presente acto
      expresamente. Si Usted no está de acuerdo tanto con nuestros Términos y
      Condiciones, como con nuestra Política de Privacidad, Usted sencillamente,
      no debe operar la Aplicación Móvil “COMUNA CONECTADA” y los Servicios que
      le ofrece la referida Aplicación Móvil, como así también, de su Sitio WEB
      http://www.comunaconectada.com
    </p>
    <p>
      Asimismo y en consecuencia a lo anteriormente indicado, la empresa le
      informa que los datos personales que se obtengan a través de la Aplicación
      Móvil “COMUNA CONECTADA” y/o Portal WEB serán transferidos a NATURALPHONE
      S.A. y serán tratados con la finalidad de enviarle, entre otros,
      publicidad o cualquier tipo de comunicación sobre información de interés
      referente a su seguridad personal, salud, etc. Al aceptar la presente
      Política de Privacidad, Usted autoriza, asimismo, expresamente la
      mencionada transferencia de datos personales y el tratamiento de los
      mismos con dicha finalidad.
    </p>
    <p>
      En cuanto a los formularios electrónicos para nuestra Plataforma WEB,
      salvo que se indique lo contrario, las respuestas a los datos solicitados
      son voluntarios sin que esto afecte la calidad del servicio suministrado,
      a menos que se indique en un inciso.
    </p>
    <p>
      “COMUNA CONECTADA” no se hace responsable de las Políticas de Privacidad
      de otros Sitios WEB en los cuales se pueda acceder a través de vínculos al
      Portal WEB http://www.comunaconectada.com.
    </p>
    <h2 class="title">1. ¿Qué tipo de información podemos solicitar?.</h2>
    <p>
      En alguna ocasión y con el fin de que Usted obtenga diferentes beneficios
      por interactuar y mantener activa en su smartphone nuestra Aplicación
      Móvil “COMUNA CONECTADA”, podremos solicitarle información como su nombre,
      nombre de usuario, dirección de correo electrónico, número telefónico,
      edad, RUT, sexo, entre otros. Al brindarnos dichos datos, Usted autoriza
      su utilización para fines promocionales, entre otras cosas. Asimismo,
      podremos enviarle información sobre productos o servicios que a Usted le
      interese conocer e incluso información de nuevos sistemas que a futuro
      sean incorporados en la Aplicación Móvil “COMUNA CONECTADA”.
      Adicionalmente, nuestra Aplicación Móvil como su Sitio WEB, pueden incluir
      encuestas opcionales donde se le pregunte por sus opiniones acerca de
      distintos productos o servicios del ámbito de la Seguridad, Salud, etc,
      como así también, de cómo Usted mejoraría nuestra Aplicación Móvil “COMUNA
      CONECTADA” y su Portal WEB http://www.comunaconectada.com.
    </p>
    <h2 class="title">2. ¿Qué hacemos con la información que obtenemos?.</h2>
    <p>La información que obtenemos, la utilizamos de distintos modos:</p>
    <ul>
      <li>Para atender las peticiones del Servicio de Atención al Cliente.</li>
      <li>
        Para análisis interno e investigación con el fin de mejorar nuestra
        Aplicación Móvil y Plataforma WEB.
      </li>
      <li>
        Para enviarle información de productos, servicios o beneficios que a
        Usted le podrían interesar.
      </li>
      <li>
        Para enviarle correos electrónicos administrativos (por ejemplo, si
        Usted olvida su contraseña).
      </li>
      <li>
        Para prevenir y detectar fraudes o utilización ilegítima de nuestra
        Aplicación Móvil y Sitio WEB.
      </li>
      <li>
        Para realizar encuestas y promociones, o bien, enviar noticias o
        informaciones que le podrían interesar.
      </li>
      <li>
        Usted autoriza que los datos personales que proporcione serán utilizados
        también para los fines anteriormente indicados, aceptando dicho
        tratamiento.
      </li>
    </ul>

    <h2 class="title">3. ¿Quién más tiene acceso a su Información?.</h2>
    <p>
      Nuestras empresas afiliadas, distribuidores comunales y otros organismos
      que trabajen con nosotros o que hayan generado acuerdos comerciales con
      nuestra compañía pueden tener acceso a su información, como por ejemplo
      Municipios, Gobierno, Fundaciones y demás, con los cuales podríamos tener
      un convenio de colaboración a futuro para la explotación de la Aplicación
      Móvil y Sitio WEB “COMUNA CONECTADA”, con el fin de gestionarla en nuestro
      nombre y proporcionarle a su persona la información y los servicios que
      Usted haya solicitado, o bien, que le queramos brindar, lo cual Usted
      acepta y brinda consentimiento a efectos de que sus datos personales sean
      remitidos y/o transferidos a estas empresas u organismos, así como el
      tratamiento que realicen sobre los mismos.
    </p>
    <p>
      Algunas de estas empresas u organismos, podrían procesar su información en
      países que estén fuera de la zona económica europea (EEA), como Estados
      Unidos y Japón. La legislación de protección de datos de estos países
      podría no ofrecer el mismo nivel de protección que la brindada por EEA. Si
      no desea que transfiramos sus datos de este modo, no acceda a nuestro
      Sitio WEB o Aplicación Móvil “COMUNA CONECTADA”, ni los utilice.
    </p>
    <p>
      En circunstancias excepcionales, podríamos revelar sus datos personales
      por mandato legal, como por ejemplo: frente actividades ilegales o
      perjudiciales. En caso de venta o reestructuración de nuestro negocio, sus
      datos podrán transferirse como parte del mismo, lo cual Usted acepta y
      autoriza.
    </p>
    <p>
      También trabajamos con empresas de terceros para ayudar a mejorar y a
      comprender el uso de nuestra Aplicación Móvil “COMUNA CONECTADA” y su
      Portal WEB, a fin de ofrecer así la mejor experiencia posible a su
      persona. Estas empresas generan información de uso estadístico y están
      autorizadas a usar su información personal solo en la medida de
      proporcionarnos estos servicios, lo cual Usted acepta y autoriza.
    </p>
    <h2 class="title">4. Seguridad de su Información.</h2>
    <p>
      Realizamos todos los pasos comercialmente razonables para proteger su
      información personal, como con el uso de procedimientos de configuración
      que minimicen el acceso no autorizado a su información. Sin embargo, no
      garantizamos la eliminación de cualquier riesgo de mala utilización de sus
      datos personales por parte de intrusos o hackers. Contáctenos
      inmediatamente si se entera de cualquier uso no autorizado de su
      contraseña o de otras violaciones de seguridad.
    </p>
    <h2 class="title">5. ¿Cómo Anular las Notificaciones?.</h2>
    <p>
      Si no desea recibir comunicaciones de ninguna índole de nuestra parte, le
      pedimos utilice el enlace provisto en los correos de marketing, o vaya a
      la zona de registro de su Perfil y modifique sus opciones de comunicación.
      Si no se ha registrado en la Aplicación Móvil, también puede enviarnos un
      correo electrónico a la dirección contacto@comunaconectada.com solicitando
      lo anterior, o bien, proceda a desinstalar el Aplicativo Móvil “COMUNA
      CONECTADA”, dado que la interacción para nosotros es básica establecerla
      con su persona, o bien, de Usted hacia nosotros, ya que en gran medida la
      Aplicación Móvil “COMUNA CONECTADA”, permite su participación a través de
      la interacción que tenemos todo el tiempo con su persona.
    </p>
    <p>
      Ahora bien, si existen cambios en sus datos personales, Usted podrá
      modificar y actualizar sus datos en la opción "Mi Perfil" inserto en la
      Aplicación Móvil “COMUNA CONECTADA”.
    </p>
    <h2 class="title">6. ¿Contacto con nuestra Empresa?</h2>
    <p>Si tiene alguna pregunta de carácter general, contáctese con el Servicio de Atención al Cliente mediante el correo electrónico contacto@comunaconectada.com.
</p>
  </div>
      <div class="footer">
      <div class="wrap">
        <img src="../../assets/img/Logo.svg" width="238" alt="" />
        <div class="footer-list">
          <router-link class="footer-link" to="/">Preguntas Frecuentes</router-link>
          <router-link class="footer-link" to="/terminos-y-condiciones">Términos y Condiciones</router-link>
          <router-link class="footer-link" to="/politica-de-privacidad">Políticas de Privacidad</router-link>
          <router-link class="footer-link" to="/">Presentación APP</router-link>
        </div>
      </div>
    </div>
</main>
</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0,0);
  },
};
</script>

<style lang="scss">
@import '../../assets/css/webflow.css';
@import '../../assets/css/normalize.css';
@import '../../assets/css/region-conectada-9a28433434d626876a891d.webflow.css';
    .title {
        color: #000;
    }
    span {
  font-weight: 800;
}
    .terminos-container {
        margin: 0 auto;
        width: 90vw;
        margin-top: 130px;
        padding-right: 10vw;
        padding-left: 10vw;
        text-align: justify;
    }
/*     h1 {
        font-size: 1.2rem;
        line-height: 30px;
    }
    h2 {
        font-size: 1rem;
    } */

</style>
