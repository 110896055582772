<template>
	<!--v-container fluid style="background-color: #F7B53F;" fill-height class="d-flex flex-column justify-center"></v-container-->
	<v-container fluid style="background-color: #fff;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<img :src="require('@/assets/img/Logo.svg')" style="max-width: 250px; width: 100%;"/>
		<!-- <div class="align-center justify-center mt-4">
			<img :src="require('@/assets/Img/appIconsLogos/sosclickmujerlogo.jpg')"
				class="mx-1 app-icon-logo"/>
			<img :src="require('@/assets/Img/appIconsLogos/sosaguaslogo.jpg')"
				class="mx-1 app-icon-logo"/>
			<img :src="require('@/assets/Img/appIconsLogos/comunal.jpg')"
				class="mx-1 app-icon-logo"/>
		</div> -->
		
		<div class="align-center justify-center text-center "> <p class="mb-0 black--text"><strong>Sé parte de la red de Región Conectada.</strong></p></div>
			<div id="features" class="feature-promo">
			<p data-w-id="4593c4b7-56e8-060a-be57-648b0e08fc6c" style="opacity:1" class="paragraph-2">
			
			El Viernes 20 de Octubre, La Gobernación de la Región de Arica y Parinacota junto a Fundación Valcar 
			lanzaron la Aplicación Móvil de Seguridad Preventiva “REGIÓN CONECTADA”.<br>
			Entre los primeros 1000 nuevos registrados se sortearán 50 cámaras IP, para así poder monitorear su hogar o negocio desde cualquier 
			lugar a través de esta aplicación la que además le permitirá realizar denuncias de delitos e incivilidades y 
			comunicarse directamente con operadores a través de video conferencia, contará además con la posibilidad de crear 
			su propia red de seguridad con personas de su confianza que le darán el primer apoyo ante un hecho delictual 
			que haya sufrido o que haya sido testigo.<br><br>
			Los ganadores serán publicados en la página WWW.REGIONCONECTADA.COM.</p>
		</div>
		<div class="login-form-container d-flex justify-center mt-8">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1"
				style="max-width: 400px;width: 100%;"
			>
				<v-text-field
					v-model="name"
					:rules="requiredRule"
					label="Nombre"
					required
				
				></v-text-field>
				<v-text-field
					v-model="lastname"
					:rules="requiredRule"
					label="Apellido"
					required
				
				></v-text-field>
				<v-text-field
					v-model="email"
					:rules="emailRules"
					label="Email"
					required
				
				></v-text-field>
				<v-text-field
					v-model="password"
					autocomplete="off"
					name="pass"
					:rules="passwordRules"
					label="Contraseña"
				
					required
					validate-on-blur
					:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-text-field
					v-model="password_confirmation"
					autocomplete="off"
					name="passconfirm"
					:rules="passwordConfirmRules"
					label="Confirmar Contraseña"
				
					required
					validate-on-blur
					:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-btn
					:disabled="!valid"
					color="#5E85EF"
					dark
					:loading="loading"
					@click="startSignUp"
				>
					Registrar Cuenta
				</v-btn>
				<v-btn
					:disabled="!valid || loading"
					color="white"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'Home'})"
				>
					Volver al Inicio
				</v-btn>
			</v-form>
		</div>
	</v-container>
</template>

<script>
import { signUp, login } from '@/helpers/api/sosdenuncias/sosdenuncias'

export default {
	name: 'Register',
	computed: {
		passwordConfirmRules() {
			return [
				(this.password_confirmation !== '') || 'Confirmación de contraseña es requerida',
				(this.password_confirmation === this.password) || 'Las contraseñas no coinciden'
			]
		}
	},
	data: () => ({
		valid: true,
		loading: false,
		showMessage: false,
		message: '',
		messageColor: 'error',
		name: '',
		lastname: '',
		email: '',
		requiredRule: [
			v => !!v || 'Este campo es obligatorio'
		],
		emailRules: [
			v => !!v || 'Email es obligatorio',
			v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
		],
		password: '',
		password_confirmation: '',
		passwordRules: [
			v => !!v || 'Contraseña es requerida',
			(v) => (v && v.length >= 8) || 'La contraseña debe ser de 8 caracteres mínimo'
		],
		passType: true,
		code: null
	}),
	mounted(){
		this.code = this.$route.query.code
	},
	methods: {
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		async startSignUp () {
			const data = {
				name: this.name,
				lastname: this.lastname,
				email: this.email,
				password: this.password,
				password_confirmation: this.password_confirmation,
				share_code: this.code
			}
			this.loading = true
			const signUpResp = await signUp(data)

			if(signUpResp.code === 200){
				this.message = 'Cuenta creada correctamente, estamos ingresando y redireccionándote a la pantalla previa.'
				this.messageColor = 'success'
				this.showMessage = true
				setTimeout(() => {
					this.startLogin ()
				}, 3000)
			}
			else{
				this.loading = false
				this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
				this.messageColor = 'warning'
				this.showMessage = true
			}
		},
		async startLogin () {
			this.loading = true
			const loginResp = await login({email: this.email, password: this.password})
			this.loading = false
			
			if(loginResp.code === 200){
				if(this.$route.query && this.$route.query.redirect_to){
					this.$router.push({ path: this.$route.query.redirect_to })
				}
			}
			else{
					this.$router.push({ name: 'Login', query: this.$route.query })
			}
		},
	},
};
</script>
<style scoped>
	.login-form-container{
		width: 100%;
	}
	.app-icon-logo{
		max-width: 70px;
		width: 100%;
		border-radius: 100%;
		opacity: 0.7;
	}
	.app-icon-logo:hover{
		opacity: 1;
	}
</style>
