<template>
<main style="background-color: #F7B53F; height:100vh;">
	<v-container fluid  fill-height class="d-flex flex-column justify-center" v-if="userInfo">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<img :src="require('@/assets/img/Logo.svg')" class="mb-10" style="max-width: 250px; width: 100%;"/>
		<div class="align-center justify-center text-center mt-8">
			<p class="white--text">Ingresarás a la red de seguridad del usuario {{userInfo.name + ' ' + userInfo.lastname}} de la app Región Conectada</p>
			<p class="white--text">Puedes aceptar o rechazar esta invitación</p>
		</div>
		<div class="d-flex flex-column align-center justify-center mt-8" v-if="accepted">
			<v-alert
				type="success"
				dense
				prominent
				max-width="800px"
			>
				Ahora formas parte de la red del usuario {{userInfo.name + ' ' + userInfo.lastname}}, por favor ingresa a la app Región Conectada para recibir alertas y notificaciones de tus redes de seguridad.
			</v-alert>
			<v-btn
				:disabled="!valid || loading"
				color="white"
				text
				class="mx-2"
				width="300px"
				@click="$router.push({name: 'Home'})"
			>
				Volver al Inicio
			</v-btn>
		</div>
		<div class="login-form-container d-flex justify-center mt-8" v-else>

			<v-row>
				<v-col cols="12" md="6" style="display:flex; justify-content:center;">
			<v-btn
				:disabled="!valid"
				color="#5E85EF"
				dark
				class="mx-2"
				width="300px"
				:loading="loading"
				@click="acceptInvitation"
			>
				Aceptar Invitación
			</v-btn>
			</v-col>
			<v-col cols="12" md="6" style="display:flex; justify-content:center;">
			<v-btn
				:disabled="!valid || loading"
				color="#5E85EF"
				dark
				class="mx-2"
				width="300px"
				@click="$router.push({name: 'index'})"
			>
				Rechazar
			</v-btn>
			</v-col>
			</v-row>

		</div>
	</v-container>
</main>

</template>

<script>
import { getInvitingUserInfo, enterToNetworkWithInvitationCode } from '@/helpers/api/sosdenuncias/sosdenuncias'

export default {
	name: 'NetworkInvite',
	data: () => ({
		valid: true,
		loading: false,
		code: '',
		cmp: '',
		showMessage: false,
		message: '',
		messageColor: 'error',
		userInfo: null,
		accepted: false
	}),
	mounted(){
		this.code = this.$route.query.code
		this.cmp = this.$route.query.cmp
		this.getInvitingUser(this.code)
	},
	methods: {
		async getInvitingUser(){
			const userInfo = await getInvitingUserInfo({user_code: this.code, company_id: this.cmp})
			if(userInfo.code === 200){
				this.userInfo = userInfo.user
			}
			else{
				this.$router.push({name: 'Login', query: {redirect_to: 'network_invite?code=' + this.code + '&cmp=' + this.cmp}})
			}
		},
		async acceptInvitation(){
			this.loading = true
			const userInfo = await enterToNetworkWithInvitationCode({
				company_id: this.cmp,
				invitation_code: this.code,
				status: 2
			})
			this.loading = false
			if(userInfo.code === 200){
				this.accepted = true
			}
			else{
				this.message = 'Lo sentimos, ha ocurrido un error al procesar la solicitud. Por favor inténtalo nuevamente.'
				this.messageColor = 'warning'
				this.showMessage = true
			}
		},

	},
};
</script>
<style scoped>
	.login-form-container{
		width: 100%;
	}	
</style>
